import React, { useEffect, Suspense, useLayoutEffect } from "react";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { actFetchPropertiesRequest, actFetchRatesRequest } from "./actions";
import { useDispatch } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
//Routes
import Routes from "./components/layouts/Routes";
import { ReactQueryDevtools } from "react-query/devtools";
import { API } from "./http/API";

// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/slick-carousel/slick/slick.css";
// import "../node_modules/magnific-popup/dist/magnific-popup.css";
// import "../node_modules/react-datepicker/dist/react-datepicker.css";
// import "./assets/css/animate.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/scss/style.scss";

// import "./assets/css/default.css";
// import "./index.css";
// React.lazy(() => import("./assets/scss/style.scss"));

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});
const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();

  const removeCache = () => {
    API.get("/clear-cache")
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log(response.data || "Cache has been flushed.");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dispatch(actFetchPropertiesRequest());
    dispatch(actFetchRatesRequest());
    removeCache();
  }, []);

  return (
    <>
      <Router>
        <Suspense fallback={<div></div>}>
          <ScrollToTop>
            <Preloader />
            <QueryClientProvider client={queryClient}>
              <Routes />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ScrollToTop>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
