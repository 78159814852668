import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "../utils/loader/loadable";
import pMinDelay from "p-min-delay";
import CommonLoader from "../layouts/commonLoader";

// Pages

const Home = loadable(() => pMinDelay(import("../pages/Home"), 250), {
  fallback: <CommonLoader />,
});
// const Home = React.lazy(() => import("../pages/Home")); old import without loader
const About = loadable(() => pMinDelay(import("../pages/About"), 250), {
  fallback: <CommonLoader />,
});
const Services = loadable(() => pMinDelay(import("../pages/Services"), 250), {
  fallback: <CommonLoader />,
});
const InvestmentAdvisory = loadable(
  () => pMinDelay(import("../pages/InvestmentAdvisory"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const HolidayHomes = loadable(
  () => pMinDelay(import("../pages/HolidayHomes"), 250),
  {
    fallback: <CommonLoader />,
  }
);

const InteriorDesign = loadable(
  () => pMinDelay(import("../pages/InteriorDesign"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const Properties = loadable(
  () => pMinDelay(import("../pages/Properties"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const Rooms = loadable(() => pMinDelay(import("../pages/Rooms"), 250), {
  fallback: <CommonLoader />,
});
const PropertyInner = loadable(
  () => pMinDelay(import("../pages/PropertyInner"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const Accomplishments = loadable(
  () => pMinDelay(import("../pages/Accomplishments"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const AccomplishmentsInner = loadable(
  () => pMinDelay(import("../pages/AccomplishmentsInner"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const ListYourProperty = loadable(
  () => pMinDelay(import("../pages/ListYourProperty"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const Blogs = loadable(() => pMinDelay(import("../pages/Blogs"), 250), {
  fallback: <CommonLoader />,
});
const BlogInner = loadable(() => pMinDelay(import("../pages/BlogInner"), 250), {
  fallback: <CommonLoader />,
});
const Media = loadable(() => pMinDelay(import("../pages/Media"), 250), {
  fallback: <CommonLoader />,
});
const MediaInner = loadable(
  () => pMinDelay(import("../pages/MediaInner"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const Faqs = loadable(() => pMinDelay(import("../pages/Faqs"), 250), {
  fallback: <CommonLoader />,
});
const Contact = loadable(() => pMinDelay(import("../pages/Contact"), 250), {
  fallback: <CommonLoader />,
});
const RefundPolicy = loadable(
  () => pMinDelay(import("../pages/RefundPolicy"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const PrivacyPolicy = loadable(
  () => pMinDelay(import("../pages/PrivacyPolicy"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const TermsConditions = loadable(
  () => pMinDelay(import("../pages/TermsConditions"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const Bookings = loadable(() => pMinDelay(import("../pages/Bookings"), 250), {
  fallback: <CommonLoader />,
});
const Checkout = loadable(() => pMinDelay(import("../pages/Checkout"), 250), {
  fallback: <CommonLoader />,
});
const WishList = loadable(() => pMinDelay(import("../pages/WishList"), 250), {
  fallback: <CommonLoader />,
});
const Register = loadable(() => pMinDelay(import("../pages/Register"), 250), {
  fallback: <CommonLoader />,
});
const Login = loadable(() => pMinDelay(import("../pages/Login"), 250), {
  fallback: <CommonLoader />,
});
const Verification = loadable(
  () => pMinDelay(import("../pages/Verification"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const Accounts = loadable(() => pMinDelay(import("../pages/account"), 250), {
  fallback: <CommonLoader />,
});
const UserBookings = loadable(
  () => pMinDelay(import("../pages/account/user-bookings"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const BookingDetail = loadable(
  () => pMinDelay(import("../pages/account/booking-detail"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const TrackOrder = loadable(
  () => pMinDelay(import("../pages/account/order-tracking"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const ResetPassword = loadable(
  () => pMinDelay(import("../pages/account/reset-password"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const AccountDetails = loadable(
  () => pMinDelay(import("../pages/account/account-details"), 250),
  {
    fallback: <CommonLoader />,
  }
);
const Error404 = loadable(() => pMinDelay(import("../pages/Error404"), 250), {
  fallback: <CommonLoader />,
});

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" exact component={About} />
      <Route path="/services" exact component={Services} />
      <Route path="/investment-advisory" exact component={InvestmentAdvisory} />
      <Route path="/holiday-homes" exact component={HolidayHomes} />
      <Route path="/interior-design" exact component={InteriorDesign} />
      <Route path="/properties" exact component={Properties} />
      <Route path="/properties/:id" exact component={Rooms} />
      <Route path="/properties/:cat/:id" exact component={PropertyInner} />
      <Route path="/accomplishments/:id" exact component={Accomplishments} />
      <Route
        path="/accomplishments/:loca/:id"
        exact
        component={AccomplishmentsInner}
      />
      <Route path="/list-your-property" exact component={ListYourProperty} />
      <Route path="/blogs" exact component={Blogs} />
      <Route path="/blog/:id" exact component={BlogInner} />
      <Route path="/media" exact component={Media} />
      <Route path="/media/:id" exact component={MediaInner} />
      <Route path="/faqs" exact component={Faqs} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/refund-policy" exact component={RefundPolicy} />
      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      <Route path="/terms-and-conditions" exact component={TermsConditions} />

      <Route path="/bookings" exact component={Bookings} />
      <Route path="/checkout" exact component={Checkout} />
      <Route path="/saved" exact component={WishList} />
      <Route path="/login" exact component={Login} />
      <Route path="/register" exact component={Register} />
      <Route path="/verification" exact component={Verification} />

      <Route path="/account" exact component={Accounts} />
      <Route path="/account/bookings" exact component={UserBookings} />
      <Route
        path="/account/booking-details/:id"
        exact
        component={BookingDetail}
      />
      <Route path="/account/order-tracking/:id" exact component={TrackOrder} />

      <Route path="/account/reset-password" exact component={ResetPassword} />
      <Route path="/account/account-details" exact component={AccountDetails} />

      <Route path="*" component={Error404} />
    </Switch>
  );
};

export default Routes;
